/* eslint-disable react/jsx-props-no-spreading */
import { ConnectKitProvider } from 'connectkit';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { WagmiConfig } from 'wagmi';

import defaultSEOConfig from '../../next-seo.config';
import { siweClient } from '../api/lib/siweClient';
import { Chakra } from '~/lib/components/Chakra';
import { AppProvider } from '~/lib/contexts/AppContext';
import { connectKitClient } from '~/lib/ethereum/ConnectKit';

import '@fontsource/montserrat/latin.css';
import '~/lib/styles/globals.css';

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <WagmiConfig client={connectKitClient}>
      <siweClient.Provider
        // Optional parameters
        enabled // defaults true
        nonceRefetchInterval={300000} // in milliseconds, defaults to 5 minutes
        sessionRefetchInterval={300000} // in milliseconds, defaults to 5 minutes
        signOutOnDisconnect={false} // defaults true
        signOutOnAccountChange={false} // defaults true
        signOutOnNetworkChange={false} // defaults true
      >
        <ConnectKitProvider
          customTheme={{
            '--ck-connectbutton-font-size': '1rem',
            '--ck-connectbutton-border-radius': '25px',
            '--ck-connectbutton-box-shadow': '0 5px black',
            '--ck-font-family': '"Montserrat", sans-serif',
          }}
        >
          <AppProvider>
            <Chakra>
              <Head>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
              </Head>
              <DefaultSeo {...defaultSEOConfig} />
              <GoogleAnalytics trackPageViews />
              <Component {...pageProps} />
            </Chakra>
          </AppProvider>
        </ConnectKitProvider>
      </siweClient.Provider>
    </WagmiConfig>
  );
};

export default App;
